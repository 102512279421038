<template>
  <div class="core-logo">
    <b-img
      :src="require('@/assets/images/logo/logo.png')"
      height="70"
      width="70"
      alt="Logo"
    />
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default ({
  components: {
    BImg,
  },
})
</script>
